import { Reservation, BookingRules, Membership, Holiday } from "../../models/models";
import React from "react";
import { avaliableCredits, DayType } from "../../utils/reservation-utils";
import { Row, Col, Table, Container } from "react-bootstrap";
import membershipType from "../../screens/membership/membership-type";
import { months } from "../../utils/calendar-utils";
import { lang, selctedLang } from "../../lang";

export enum CalendarScrollState {
    CREATION_DATE,
    END_DATE,
    NONE
}

interface AvailableCreditsProps {
    reservations: Reservation[],
    year: number,
    month: number,
    bookingRules: BookingRules,
    membership: Membership,
    holidays: Holiday[],
    onMonthUpdate: (update: CalendarScrollState) => void,
}


// 0: {date: "2020-7", WEEK_END: 2, WORKING_DAY: 7}
// 1: {date: "2020-8", WEEK_END: 0, WORKING_DAY: 7}
// 2: {date: "2020-9", WEEK_END: 3, WORKING_DAY: 5

class AvailableCredits extends React.Component<AvailableCreditsProps, { [k: string]: any }> {
    constructor(props: any) {
        super(props)
    }

    GetTable = (_availableSlots: any[]) => {
        let weekendSum = 0
        let workingdaySum = 0


        let weekData = [];
        let weekendData = [];

        for (let index = 0; index < _availableSlots.length; index++) {
            const element = _availableSlots[index];

            const monthNumber = months[element.date.split('-')[1]].charAt(0)

            weekData.push((<div key={`${index}-${index}`}>
                <div className='monthWeek'>{monthNumber}</div>
                <div className='monthWeekCount'>{element[DayType.WORKING_DAY]}</div>
            </div>))

            weekendData.push(<div key={`${index}`}>
                <div className='monthWeekend'>{monthNumber}</div>
                <div className='monthWeekendCount'>{element[DayType.WEEK_END]}</div>
            </div>)

            weekendSum = weekendSum + +element[DayType.WEEK_END]
            workingdaySum = workingdaySum + +element[DayType.WORKING_DAY]
        }


        return (
            <Row className='availableCredits'>
                <Col xs={5}>
                    <Row style={{ height: 44, float: 'right', flexWrap: 'nowrap' }}>
                        <div >
                            <div className='totalWeek'>{workingdaySum}</div>
                        </div>
                        {weekData}
                    </Row>
                </Col>
                <Col></Col>
                <Col xs={5}>
                    <Row style={{ height: 44, flexWrap: 'nowrap' }}>
                        {weekendData}
                        <div>
                            <div className='totalWeekend'>{weekendSum}</div>
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }

    render() {
        const { reservations, year, month, bookingRules, membership, holidays } = this.props
        const _avaliable: any[] = avaliableCredits(reservations, year, month, bookingRules, membership, holidays)

        if (+_avaliable[0].date.split('-')[1] === month) {
            this.props.onMonthUpdate(CalendarScrollState.CREATION_DATE)
        } else if (+_avaliable[_avaliable.length - 1].date.split('-')[1] === month) {
            this.props.onMonthUpdate(CalendarScrollState.END_DATE)
        }
        else {
            this.props.onMonthUpdate(CalendarScrollState.NONE)
        }

        if (!year || month < 0)
            return (<div></div>)

        return this.GetTable(_avaliable)
    }
}


export default AvailableCredits;